import { buscarValorPorCaminhoConcatenado } from 'src/app/shared/utils/buscar-valor-por-caminhos-concatenados';
import { condicao } from '../interfaces/tabela-configs.interface';
import { CondicaoLabel } from '../interfaces/dados-config.interface';

export function buscarClasseCondicional(valorAtual: any, condicoes: condicao[] = []) {
  if (condicoes.length === 0) {
    return '';
  } else if (condicoes.length === 1) {
    return condicoes[0].classeCorrespondente ?? '';
  }
  let classes = '';
  condicoes.forEach(condicao => {
    if (condicao.igualA === valorAtual) {
      classes += ` ${condicao.classeCorrespondente}`;
    }
  });

  return classes;
}

export function buscarPropiedadeCondicional(obj: any, caminho: any, condicoes: condicao[] = []) {
  const valorAtual = buscarValorPorCaminhoConcatenado(obj, caminho);
  if (condicoes.length === 0) {
    return '';
  } else if (condicoes.length === 1) {
    return (
      condicoes[0].textoCorrespondente ??
      buscarValorPorCaminhoConcatenado(obj, condicoes[0].propiedadeCorrespondente) ??
      ''
    );
  }
  let propiedadeValor: string | number | undefined = '';
  condicoes.forEach((condicao: any) => {
    if (condicao.igualA === valorAtual) {
      propiedadeValor =
        condicao?.textoCorrespondente ?? buscarValorPorCaminhoConcatenado(obj, condicao.propiedadeCorrespondente);
    }
  });

  return propiedadeValor;
}

export function buscarIconeCondicional(obj: any, caminho: any, condicoes: condicao[] = []) {
  const valorAtual = buscarValorPorCaminhoConcatenado(obj, caminho);
  if (condicoes.length === 0) {
    return '';
  } else if (condicoes.length === 1) {
    return condicoes[0].iconeCorrespondente ?? '';
  }
  let iconeClasse: string | number | undefined = '';
  condicoes.forEach((condition: any) => {
    if (condition.igualA === valorAtual) {
      iconeClasse = condition?.iconeCorrespondente;
    }
  });

  return iconeClasse;
}

export function buscarLabelCondicional(obj: any, data: CondicaoLabel) {
  const valorAtual = buscarValorPorCaminhoConcatenado(obj, data.propriedade);

  if (data.condicoes.length === 0) {
    return '-';
  } 

  let nomeDaLabel: string = '';
  data.condicoes.forEach((condicao: any) => {
    if (condicao.igualA === valorAtual) {
      nomeDaLabel = condicao?.textoCorrespondente ?? '-';
    }
  });

  return nomeDaLabel;

}