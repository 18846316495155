export function buscarValorPorCaminho(obj: any, propiedadeCaminho?: string): any {
  if (!propiedadeCaminho || !obj) {
    return;
  }
  const caminhos = propiedadeCaminho.split('.');
  let resultado = obj;

  for (let prop of caminhos) {
    resultado = resultado[prop] ?? undefined;
    if (resultado === undefined) {
      break;
    }
  }

  return resultado;
}
