export enum Endpoints {
  ufs = 'ufs',
  cidadesPorUf = 'cidades/uf',
  usuarios = 'usuarios',
  clientes = 'clientes',
  portais = 'portais',
  portaistipos = 'portais-tipos',
  planosAcomodacoes = 'planos-acomodacoes',
  planosAbrangencias = 'planos-abrangencias',
  planosContratacoes = 'planos-contratacoes',
  planosSegmentacoes = 'planos-segmentacoes',
  categorias = 'categorias',
  portaisTipos = 'portais-tipos'
}
