import { buscarValorPorCaminho } from 'src/app/shared/utils/buscar-valor-por-caminho';

export function buscarValorPorCaminhoConcatenado(obj: any, template: any) {
  const templateArray: string[] = template?.split(' ');
  let templatePreenchido: any[] = [];
  if (templateArray?.length === 1) {
    return buscarValorPorCaminho(obj, template);
  } else {
    templateArray?.forEach((key: any) => {
      const propiedadeValor = buscarValorPorCaminho(obj, key);
      templatePreenchido.push(propiedadeValor === undefined ? key : propiedadeValor);
    });
    return templatePreenchido.join(' ');
  }
}
